<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
      <el-breadcrumb-item to="/incomeList">申请提现</el-breadcrumb-item>
      <el-breadcrumb-item>上传信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-divider></el-divider>

    <div
      style="
        color: #333333;
        font-size: 20px;
        line-height: 2;
        margin-bottom: 10px;
      "
    >
      提现银行
    </div>

    <div
      v-if="myBankMsg !== null"
      style="display: flex; padding: 22px 20px; background-color: #f7f7f7"
    >
      <div style="font-size: 14px; color: #333333; margin-right: 12px">
        提现至
      </div>
      <div>
        <div style="font-size: 14px; color: #333333">
          {{ myBankMsg.bankName }} (**** {{ myBankMsg.bankCard.slice(-4) }})
          <span
            @click="jumpRouter"
            style="color: #ff004d; cursor: pointer;"
            >{{title}}</span
          >
        </div>
        <div style="color: #999999; font-size: 12px; margin-top: 5px">
          预计到账时间{{$timestampToString(arrivalTime)}}
        </div>
      </div>
    </div>
    <div
      v-else
      style="display: flex; padding: 22px 20px; background-color: #f7f7f7"
    >
      <el-link @click="$router.push('/setbankcard/Msmchecking/'+ $store.state.usermsg.webLoginType)">去绑定银行卡</el-link>
    </div>
    <div
      style="
        color: #333333;
        font-size: 20px;
        line-height: 2;
        margin-top: 40px;
        margin-bottom: 10px;
      "
    >
      选择开票方式
    </div>
    <div style="border: #e9e9e9 solid 1px; padding: 24px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div>
          <div style="color: #333333; margin-bottom: 10px">
            {{ companyData.paymentCompany }}
          </div>
          <div style="display: flex">
            <div style="color: #999999; font-size: 12px">
              开票金额:<span
                style="color: #333333; font-weight: 500; margin-right: 30px"
                >￥{{ companyData.totalMoney|money }}</span
              >
            </div>
            <div style="color: #999999; font-size: 12px">
              税费:<span
                style="color: #333333; font-weight: 500; margin-right: 30px"
                >￥{{ companyData.tax |money }}</span
              >
            </div>
            <div style="color: #999999; font-size: 12px">
              到账金额:<span
                style="color: #333333; font-weight: 500; margin-right: 30px"
                >￥{{ companyData.money |money }}</span
              >
            </div>
            <div
              style="color: #ff004d; font-size: 12px; cursor: pointer"
              @click="incomeListDialogVisible = true"
            >
              账单明细
            </div>
          </div>
        </div>

        <div>
          <el-button size="small" @click="invoiceDialogVisible = true"
            >发票抬头</el-button
          >
          <el-button
            size="small"
            :data-clipboard-text="copy"
            class="copy"
            @click="$popup({msg:'复制成功',type:'success'})"
            >复制</el-button
          >
        </div>
      </div>

      <el-divider></el-divider>

      <el-radio v-model="invoiceType" label="1">上传电子发票</el-radio>
      <el-radio v-model="invoiceType" label="2">邮寄发票</el-radio>

      <div
        v-show="invoiceType === '2'"
        style="
          color: #666666;
          font-size: 12px;
          line-height: 27px;
          padding-top: 24px;
        "
      >
        <div>收件人：{{ companyData.riseData ? companyData.riseData.receiptName : ""}}</div>
        <div>电　话：{{ companyData.riseData ? companyData.riseData.receiptTel : ""}}</div>
        <div>地　址：{{ companyData.riseData ? companyData.riseData.receiptAddress : ""}}</div>
      </div>

      <div style="display: flex; align-items: flex-end; margin-top: 24px">
        <el-upload
         v-show="invoiceUrl.length <=2"
          accept="image/jpeg,image/png"
          style="margin-right: 14px"
          :action="uploadApi"
          :headers="{ ...headers, fileType: 'image' }"
          :show-file-list="false"
          :on-success="onSuccess"

        >
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 140px;
              height: 100px;
              border: #c3c3c3 1px dashed;
            "
          >
            <img
              src="../assets/income/upload-cloud.png"
              alt="upload-placeholder"
              style="
                margin-top: 31px;
                margin-bottom: 11px;
                width: 16px;
                height: 16px;
              "
            />

            <div style="color: #ff004d; font-size: 12px">
              {{ invoiceType === "1" ? "上传打款发票" : "上传快递凭证" }}
            </div>
          </div>
        </el-upload>
           <div v-for="item in invoiceUrl" :key="item"    v-show="invoiceUrl.length > 0" class="mask">
               <div class="mask1"  @click="deleteimg(item)" >
                   <i class="el-icon-delete"></i>
                   <p>删除</p>
               </div>
            <img
            :src="item"
            style="width: 140px;height:100px; "
            alt="cover"
          />
           </div>

        <span style="color: #999999; font-size: 12px">
          {{
            invoiceType === "1"
              ? "请根据付款公司的发票抬头开提现发票"
              : "请上传您邮寄出的清晰的快递单号图"
          }}
        </span>
      </div>
    </div>

    <div style="display: flex; margin-top: 45px; justify-content: center">
      <el-button type="info" @click="$router.push('/incomeList')">上一步</el-button>
      <el-button type="primary" @click="beforeSubmit()">确认提现</el-button>
    </div>

    <el-dialog
      title="账单明细"
      center
      :visible.sync="incomeListDialogVisible"
      width="838px"
    >
      <el-table :data="companyData.incomeList" style="width: 100%">
        <el-table-column prop="title" label="账单名称"> </el-table-column>
        <el-table-column
          prop="type"
          label="账单分类"
          :formatter="getOrderTypeById"
        >
        </el-table-column>
        <el-table-column prop="paymentCompany" label="付款方">
        </el-table-column>
        <el-table-column prop="updateTime" v-slot="{ row }" label="时间">
          {{ $timestampToString(row.updateTime) }}
        </el-table-column>
        <el-table-column prop="totalMoney" label="金额(元)">
             <template slot-scope="scope">
                {{scope.row.totalMoney| money }}
            </template>
             </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="发票抬头"
      center
      :visible.sync="invoiceDialogVisible"
      width="30%"
    >
      <div class="rise">
        <div>
          <span>单位名称</span><span>{{
            companyData.riseData ? companyData.riseData.receiptName : ""
          }}</span>
        </div>
        <div>
          <span>税号</span><span>{{
            companyData.riseData ? companyData.riseData.receiptNo : ""
          }}</span>
        </div>
        <div>
          <span>单位地址</span><span>{{
            companyData.riseData ? companyData.riseData.receiptAddress : ""
          }}</span>
        </div>
        <div>
          <span>电话号码</span><span>{{
            companyData.riseData ? companyData.riseData.receiptTel : ""
          }}</span>
        </div>
        <div>
          <span>开户银行</span><span>{{
            companyData.riseData ? companyData.riseData.receiptBank : ""
          }}</span>
        </div>
        <div>
          <span>银行账户</span><span>{{
            companyData.riseData ? companyData.riseData.receiptBankNo : ""
          }}</span>
        </div>
        <div>
          <span>发票类型</span><span>{{
            companyData.riseData
              ? companyData.riseData.receiptType === 1
                ? "增值税发票"
                : "普通发票"
              : ""
          }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="invoiceDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :data-clipboard-text="copy"
          @click="$popup({msg:'复制成功',type:'success'})"
          >复 制</el-button
        >
      </span>
    </el-dialog>

    <el-dialog

      title="提示"
      :visible.sync="passwordDialogVisible"
      width="30%"
      center
      :close-on-click-modal='false'
    >
      <el-input
        v-model="password"
        placeholder="输入您设置的钱包密码"
        :maxlength="6"
        show-password
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store"
import wallet from "@/api/wallet"
import md5 from "md5"
import ClipboardJS from "clipboard"
export default {
  name: "Withdrawal",
  props: ['company'],
  data () {
    return {
      companyData: {},

      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      uploadApi: process.env.VUE_APP_BASE_API + '/api/song/fileUpload',
      invoiceType: '1',
      invoiceUrl:[],

      myBankMsg: null,

      incomeListDialogVisible: false,
      invoiceDialogVisible: false,

      password: '',
      passwordDialogVisible: false,

      copy: '',
      // 提示
      title: '',
    // 预计到账时间
    arrivalTime:'',
    }
  },
  methods: {
      deleteimg(val){
          this.$confirm('请确认是否删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
             let index = this.invoiceUrl.indexOf(val)
        this.invoiceUrl.splice(index,1)
          this.$popup({
            type: 'success',
            msg: '删除成功!'
          });
        }).catch(() => {
          this.$popup({
            type:'success',
            msg: '已取消删除'
          });
        });
    },
    onSuccess (r) {
      if (r.code === 0) {
           this.invoiceUrl.push(r.url)
        // this.invoiceUrl = r.url
      }
    },
    getMyBankMsg () {
      wallet.myBankMsg().then(r => {
        // console.log(r, 123)
        if (r.data.status == 1) {
          this.title = '修改'
        } else if (r.data.status == 0) {
          this.title = '审核中'
        } else {
          this.title = '审核失败'
        }
        this.myBankMsg = r.data
      })
    },

    getOrderTypeById (_, __, id) {
      // 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成 1001603词曲收入 1001604其他。以后新增和编辑维护
      switch (id) {
        case '1001601': return '账单分成'
        case '1001602': return '版税分成'
        case '1001603': return '词曲收入'
        case '1001604': return '其他'
        default: return '其他'
      }
    },
    beforeSubmit () {
      if (this.myBankMsg === null) {
        this.$popup({msg:'请先绑定银行',type:'warning'})
        return
      }else if (this.myBankMsg.status==0){
            this.$popup({msg:'审核中，审核通过后才能提现',type:'warning'})
            return
      }else if (this.myBankMsg.status == 2){
             this.$popup({msg:'银行卡审核失败,请重新绑定银行卡',type:'error'})
             return
      }
      if (this.invoiceUrl === '') {
        this.$popup({msg:'请上传' + (this.invoiceType === '1' ? '打款发票' : '快递凭证')})
        return;
      }
      this.passwordDialogVisible = true
    },
    submit () {
      let data = {
        list: [
          {
            "incomeIds": [],
            "invoiceUrl": this.invoiceUrl.join(','),
            "money": this.companyData.money,
            "name": this.companyData.name,
          }
        ],
        "money": this.companyData.money,
        "pwd": "",
        "tax": this.companyData.tax
      }
      if( this.companyData.incomeList !=null ) {
          this.companyData.incomeList.forEach(e => {
        data.list[0].incomeIds.push(e.id)
      })
      }else{
           data.list[0].incomeIds.push(this.companyData.id)
      }


      data.pwd = md5(this.password).toUpperCase()
      this.password = ''

      wallet.withdraw(data).then(r => {
        if (r.code === 0) this.$router.replace('/withdrawal-success')
      }).catch(e => {
        this.$popup({msg:e.msg,type:'warning'})
      })
    },
    jumpRouter(){
        // console.log()
        if(this.myBankMsg.status !=0){
             this.$router.push('/setbankcard/Msmchecking'+ this.$store.state.usermsg.webLoginType)
        }
    },

  },
    mounted () {

    this.companyData = JSON.parse(this.company)
     // 获取公司抬头信息
     wallet.wallet_getRiseByIncome(this.companyData.incomeList[0].id).then((res)=>{
        this.companyData.riseData=res.data

    })


    this.copy =
      `
单位名称  ${this.companyData.riseData ? this.companyData.riseData.receiptName : ''}
税号  ${this.companyData.riseData ? this.companyData.riseData.receiptNo : ''}
单位地址  ${this.companyData.riseData ? this.companyData.riseData.receiptAddress : ''}
电话号码  ${this.companyData.riseData ? this.companyData.riseData.receiptTel : ''}
开户银行  ${this.companyData.riseData ? this.companyData.riseData.receiptBank : ''}
银行账户  ${this.companyData.riseData ? this.companyData.riseData.receiptBankNo : ''}
发票类型  ${this.companyData.riseData ? (this.companyData.riseData.receiptType === 1 ? '增值税发票' : '普通发票') : ''}
`
    new ClipboardJS('.copy');

    this.getMyBankMsg()
  },
created () {
    // 获取预计到账时间
    this.$post('/api/wallet/moneyAndIncomeDetail',{}).then((res)=>{
        this.arrivalTime=res.data.arrivalTime
    })
},

filters:{
    money(val){
        if(val!==undefined){
             return val.toFixed(2)
        }else{
            return ''
        }



},
}

}

</script>

<style scoped lang="scss">
.rise {
  background-color: #f7f7f7;
  padding: 18px 24px;
  > div {
    color: #666666;
    font-size: 12px;
    // width: 76px;
    line-height: 38px;
    span:nth-child(1){
        display: inline-block;
        width:60px;
    }
  }
}
.mask{
    position:relative;
    margin-right:15px ;
    .mask1{
        cursor: pointer;
        bottom: 0px;
        top:0px;
        right:0px;
        left:0px;
        background-color: rgba(0,0,0,0.5);
        text-align: center;
        position: absolute;
        color: #f7f7f7;
        p{
            margin-top:5px;
            width:140px;
        }
        i{  margin-top:30px;
            display: inline-block;
            width:140px;
        }
    }
}
.dialog-footer{
    button{
        width:190px;
        height:40px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
    }
}
</style>
